<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="false" title="App test" :showBack="true"></topbar>
      <div class="content">
        <ion-button class="login-btn" @click="getLocation">getLocation</ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="openInAppBrowser('https://www.baidu.com')">native browser</ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="shareEvent">Share</ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="takePicture">takePicture</ion-button>
        <img v-lazy="$store.state.form.cropImg" :key="$store.state.form.cropImg" />
        <br />
        <br />
        <ion-button class="login-btn" @click="goCropImage">crop image </ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="goScanEvent">Scan </ion-button>
        <br />
        <br />
        <!-- <ion-button class="login-btn" @click="facebookLogin">Facebook </ion-button> -->
        <br />
        <br />
        <ion-button class="login-btn" @click="getNexwork">getNexwork </ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="getPushEvent">getPushEvent </ion-button>
        <br />
        <br />
        <ion-button class="login-btn" @click="getAppInfo">getAppInfo </ion-button>
        <br />
        <br />
        <input type="text" v-model="$store.state.deviceToken" placeholder="Card Number" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "testPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {};
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/main/me",
      });
    },
    goScanEvent() {
      this.$router.push({
        path: "/scanCode",
      });
    },
    goCropImage() {
      this.$store.state.form.photo = "https://images.unsplash.com/photo-1540804485132-9c94bcae6c66?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80";
      this.$router.push({
        path: "/cropper",
      });
    },
  },
};
</script>
